/* eslint-disable class-methods-use-this */
import _axios, { get, put, post, _delete } from '@/lin/plugin/axios'

class User {
  // 会员列表
  async getUserList(data) {
    const res = await get('/v1/member', data)
    return res
  }

  // 用户注册数统计
  async getRegidterCount(data) {
    const res = await get('/v1/member/register_count', data)
    return res
  }

  // 会员列表导出
  async exportUserList(data) {
    const res = await get('/v1/member/export', data)
    return res
  }
}

export default new User()
